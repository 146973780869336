<template>
  <div :class="propsClassName">
    <div class="form-check" v-for="(direction, inx) in directionList" :key="inx" :class="{'selected': direction===selected}">

      <input class="form-check-input" type="radio" name="flexRadioDefault"
      :id="`flexRadio${inx}`" @click="()=>chooseThis(direction)"
      :checked="direction===selected">

      <label class="form-check-label" :for="`flexRadio${inx}`">
        {{$t(`sabre.search-panel.${direction}`)}}
      </label>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'select-direction',
  props: {
    type: {
      type: String,
      default: '',
    },
    propsClassName: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      selected: 'GET_SABRE_SELECTED_FLIGHT_DIRECTION',
      directionList: 'GET_SABRE_FLIGHT_DIRECTION_LIST',
    }),
  },
  data() {
    return {
      query: this.$route.query,
    };
  },
  mounted() {
    if (this.selected === null) {
      this.setStore(this.directionList[0]);
    }
  },
  methods: {
    chooseThis(dir) {
      this.setStore(dir);
    },
    setStore(dir) {
      this.$store.commit('SET_SABRE_SELECTED_FLIGHT_DIRECTION', dir);
    },
  },
};
</script>
